/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "M Imran",
  title: "Hi, I'm M Imran",
  subTitle: emoji(
    "Lead Software Engineer with expertise in C# .NET | WebForms | MVC | .Net core | Nodejs | GraphQL| Web APIs|React | Angular and SQL Databases. 🚀 I have 9 years of experience in software development and worked in domains of Medical Billing, E-Commerce,Real Estate, Account & Finance and many other enterprise applications."
    //"A passionate Full Stack Software Developer 🚀 having an experience of building Web and Desktop applications with DotNet, JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1UXrm5Lp4gW1vzHsBnRlgCG4DuzkX7RNG/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/programmer4nd",
  linkedin: "https://www.linkedin.com/in/programmer4nd",
  gmail: "programmer4nd@gmail.com",
  gitlab: "https://gitlab.com/programmer4nd",
  facebook: "https://www.facebook.com/",
  medium: "https://medium.com/@programmer4nd",
  stackoverflow: "https://stackoverflow.com/users/3424847/m-imran",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji(
      "⚡ Creation,optimization and maintenance of SQL Databases"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "DotNet",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    }
    // ,{
    //   skillName: "docker",
    //   fontAwesomeClassname: "fab fa-docker"
    // }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Quaid i Azam University",
      logo: require("./assets/images/qau.jpg"),
      subHeader: "Master of Science in Information Technology",
      duration: "September 2012- Dec 2014",
      desc: "I was among the top 5 position holders in the department. Also I did research on Face Recognition for security systems.",
      descBullets: [
        " Won the best Programmer of The department award.",
        " Studied the optional cources of Artificaial Intelligence and NEtwork Security."
      ]
    },
    {
      schoolName: "University of the Punjab",
      logo: require("./assets/images/pu.jpg"),
      subHeader: "Bachelor of Science",
      duration: "June 2010 - Aug 2012",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Studied the Mathematic and Physics as optional courses."]
    }
  ]
};

// Your top 3 proficient stacks/tech experience 

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "75%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "95%"
    },
    {
      Stack: "Programming",
      progressPercentage: "90%"
    },
    {
      Stack: "Databases",
      progressPercentage: "90%"
    },
    {
      Stack: "Application architecture",
      progressPercentage: "80%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "React Global",
      companylogo: require("./assets/images/react.jpg"),
      date: "June 2022 – Present",
      desc: "Along with Lead Application Developer i am also taking caring of following responsibilities induviually or with relevent team members.",
      descBullets: [
        " Managing Cloud Application Services and budget.",
        " Application Code Reviews and code security.",
        " Meetings with clients and proposing suitable solutions.",
        " Managing Databases backups and jobs."
      ]
    },
    {
      role: "Lead Software Engineer",
      company: "Qatar Navigator",
      companylogo: require("./assets/images/qatarnavigator.png"),
      date: "Oct 2021 – May 2022",
      desc: `Working as a Full Stack Dot Net Developer in Technologies like Node js, .Net and React js.
      Maintenance of Server, Jobs and Configuration.
      Managing Git Repos and deployments.Working as a Full Stack Dot Net Developer in Technologies like Node js, .Net and React js. Maintenance of Server, Jobs and Configuration. Managing Git Repos and deployments.
      Skills: IT Management · Internet Information Services (IIS) · Project Management · Node.js · React.js`
    },
    {
      role: "Senior Software Engineer",
      company: "Digital Processing Systems.",
      companylogo: require("./assets/images/dps.jpg"),
      date: "Jan 2021 – Oct 2021",
      desc: " In digital processing system I worked on Some Dot Net Core APIs and also implemented Account and Finance System for Some University Administration Section"
    },
    {
      role: "Senior Web Engineer",
      company: "STech.ai",
      companylogo: require("./assets/images/stech.png"),
      date: "Jan 2020 – Dec 2020",
      desc: "During my stay with STech I develop the AI based smart attendance system with other team members using embeded devices, cameras, kafka an php."
    },
    {
      role: "Software Architech",
      company: "Advanca Technologies",
      companylogo: require("./assets/images/advanca.jpg"),
      date: "May 2017 – Jan 2020",
      desc: ` The most exciting moments in my careers were with advanca technology, where we provided hundreds of cost affective solution to small business. I can say I has the best team with me (specially our creative designer and froent end developer). and I am so proud I build so many resubale libraries and projects which help us alot to compete the industry and provide cheap solutions to our clients, This was the best part of learning in my career, and I explore very much of DotNet Technologies,
       Google APIs and SQL Databases.` 
    },
    {
      role: "Dot Net Developer",
      company: "Protege Global",
      companylogo: require("./assets/images/protege.jpg"),
      date: "April 2015 – May 2017",
      desc: ` Thanks to Protege global who gave me first appurchunity of ma career which helped me to use ma capablilites and enhance my techbology stack. I worked on support here and managed to find some cricital server sissues which were affecting the performance of applications.
       I also did my part in the development of EMS system and maintenance and enhancement of medical billing  system and operations.`
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/chh.png"),
      projectName: "Chaudry Housse",
      projectDesc: "Multi Language E-Commerce Solution with inventory management SEO Features.",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://chh.com.pk/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      //image: require("./assets/images/nextuLogo.webp"),
      projectName: "Saloon",
      projectDesc: "Saloon App for beauty saloons, which offers saloons services and products.",
      footerLink: [
        {
          name: "Visit Client Website",
          url: "https://stylemementor.com/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "PMP Basics",
      subtitle:
        "I had a great experience learning industry-aligned skills of Project Management.",
      image: require("./assets/images/pmp.jpg"),
      imageAlt: "PMP Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.simplilearn.com/skillup-certificate-landing?token=eyJjb3Vyc2VfaWQiOiIxODE3IiwiY2VydGlmaWNhdGVfdXJsIjoiaHR0cHM6XC9cL2NlcnRpZmljYXRlcy5zaW1wbGljZG4ubmV0XC9zaGFyZVwvdGh1bWJfNDkzODU1OV8xNzA5OTg1ODM5LnBuZyIsInVzZXJuYW1lIjoiTXVoYW1tYWQgSW1yYW4ifQ%3D%3D&utm_source=shared-certificate&utm_medium=lms&utm_campaign=shared-certificate-promotion&referrer=https%3A%2F%2Flms.simplilearn.com%2Fcourses%2F4328%2FPMP-Basics%2Fcertificate%2Fdownload-skillup&%24web_only=true&_branch_match_id=1303338545664553639&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXL87MLcjJ1EssKNDLyczL1k%2FVDw0pDi93zk4O8EgCAJKiNkQlAAAA"
        } 
      ]
    },
    {
      title: "AWS Cloud Practitioner",
      subtitle:
        "Got certificate of AWS Cloud Practitioner after completion of test.",
      image: require("./assets/images/awsp.jpg"),
      imageAlt: "AWS Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "#"
        } 
      ]
    },
    {
      title: "Google Hash Code",
      subtitle:
        "Participated and received the award for top position holders in google hash code two times.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google HashCode Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "#"
        }
      ]
    }, 
    {
      title: "Dice Analytics Power BI",
      subtitle:
        "Participated and get the certification after successfully completion of course and test.",
      image: require("./assets/images/dice.png"),
      imageAlt: "Dice Analytics Power BI",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://dicecamp.com/lms/certificate-public-link/7CW20ZAtwUTxd9R8kvSPGOfmaQXID4qKhNYnbilHj1FpVMBcJ3"
        }
      ]
    },

    // {
      // title: "PWA Web App Developer",
      // subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      // image: require("./assets/images/pwaLogo.webp"),
      // imageAlt: "PWA Logo",
      // footerLink: [
        // {name: "Certification", url: ""},
        // {
          // name: "Final Project",
          // url: "https://pakistan-olx-1.firebaseapp.com/"
        // }
      // ]
    // }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://blog.usejournal.com/create-a-google-assistant",
      title: "Win a Google Assistant Tshirt and $200 in Google Cloud Credits",
      description:
        "Do you want to win $200 and Google Assistant Tshirt by creating a Google Assistant Action in less then 30 min?"
    }, 
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Best Code Practices",
      subtitle: "Best Code Practices University Students seminar at React.",
      slides_url: "#",
      event_url: "#"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO EXPLORE WORLD POLITICS, HUMAN OSYCHOLOGY AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
   // "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+974-77324579",
  email_address: "programmer4nd@hotmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
